import React from "react";

import PausePresentationIcon from "@mui/icons-material/PausePresentation";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import SkipPreviousRoundedIcon from "@mui/icons-material/SkipPreviousRounded";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";

const AudioControls = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick,
  singleSong,
}) => (
  <div className="audio-controls">
    {!singleSong && (
      <button
        type="button"
        className="prev"
        aria-label="Previous"
        onClick={onPrevClick}
      >
        <SkipPreviousRoundedIcon />
      </button>
    )}
    {isPlaying ? (
      <button
        type="button"
        className={!singleSong? "pause" : "pause-single"}
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause"
      >
        <PausePresentationIcon />
      </button>
    ) : (
      <button
        type="button"
        className={!singleSong? "play" : "play-single"}
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play"
      >
        <PlayCircleFilledRoundedIcon />
      </button>
    )}

    {!singleSong && (
      <button
        type="button"
        className="next"
        aria-label="Next"
        onClick={onNextClick}
      >
        <SkipNextRoundedIcon />
      </button>
    )}
  </div>
);

export default AudioControls;
