import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const placeStore = (set) => ({
  places: [],

  initPlaces: (initPlaces) => {
    set((state) => ({
      places: initPlaces,
    }));
  },
  addPlace: (place) => {
    set((state) => ({
      places: [place, ...state.places]
    }));
  },

  removePlace: (placeId) => {
    set((state) => {
      places: state.places.filter((place) => place.id !== placeId)
    });
  },
});

const usePlaceStore = create(
  devtools(
    persist(placeStore, {
      name: "places"
    })
  )
);

export default usePlaceStore;
