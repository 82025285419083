import React, { useState } from "react";
import useAuthStore from "../stores/AuthStore";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

const Authentication = ({ setShowMap, setDisplayAuth }) => {
  const [email, setEmail] = useState(""); // email of the user
  const [password, setPassword] = useState(""); // password of the user
  const [username, setUsername] = useState(""); // username of the user
  const [Rmsg, setRMsg] = useState(""); // Registration message
  const [Lmsg, setLMsg] = useState(""); // Login message
  const [user, setUser] = useState(""); // User object after registration / login
  const [session, setSession] = useState(""); // session object after registration / login
  const [newHere, setNewHere] = useState(false);

  const supabase = useSupabaseClient();

  const updateLoginStatus = useAuthStore(
    (state) => state.updateEmailConnStatus
  );

  async function Login() {
    console.log("Logging in...");
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setLMsg(error.message);
      // setDisplayCorner(false);
      updateLoginStatus(false);
      console.log(
        `after updating emailLoggedIn ${useAuthStore.getState().emailLoggedIn}`
      );

      console.log(`error logging in user.. ${error.message}`);
    } else {
      setLMsg("Login successful");
      setUser(data.user);
      setSession(data.session);

      // if not have profile, create a profile @ user_profile table.

      // console.log(`user id: ${JSON.stringify(data.user.id)}`)
      // console.log(`user details: ${JSON.stringify(data.user)}`)
      const profile= await supabase.from('user_profile').select('user_id, email, member_since').eq('user_id', data.user.id).single();
      
      if(profile.data === null) {
        console.log(`creating a new profile..`)
        // insert a new profile.
          const { x, error } = await supabase.from("user_profile").insert({
      user_id: data.user.id,
      email: data.user.email,
      member_since: data.user.email_confirmed_at
    });

    // console.log(`here's user profile details >> ${JSON.stringify(profile.data)}`)

      }


      window.localStorage.setItem("session", data.session);
      window.localStorage.setItem(
        "pfp",
        `https://robohash.org/${email}${username}.png`
      );

      updateLoginStatus(true);
      setDisplayAuth(false);
    }
  }

  async function Register() {
    console.log(`registering...`);
    const { data, error } = await supabase.auth.signUp(
      {
        email,
        password,
      },
      {
        data: {
          username,
        },
      }
    );
    if (error) {
      setRMsg(error.message);
    } else {
      setRMsg("Check your email ;) ");
      setUser(data.user);
    }
  }

  return (
    <div className="auth-form">
      {newHere === false ? (
        <div className="login-form">
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email"
          />
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
          />

          <button className="retro email-login-btn" onClick={Login}>
            Login
          </button>

          <button className="retro" onClick={() => setNewHere(true)}>
            new here?
          </button>
          {/* <br /> */}
          <p>{Lmsg}</p>
        </div>
      ) : null}

      {newHere === true ? (
        <div className="registration-form">
          {/* <h1></h1> */}
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email"
          />
          <input
            type="text"
            onChange={(e) => setUsername(e.target.value)}
            placeholder="username"
          />
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
          />

          <button className="retro email-registration-btn" onClick={Register}>
            Register
          </button>

          <button className="retro" onClick={() => setNewHere(false)}>
            already member?
          </button>
          <br />
          <p>{Rmsg}</p>
        </div>
      ) : null}
    </div>
  );
};

export default Authentication;
