import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_CLIENT_URL,
  process.env.REACT_APP_SUPABASE_ANONKEY
);

const getAllPlaces = async () => {
  try {
    const { data, error } = await supabase.from("places").select("*");
    return data;
  } catch (error) {
    console.log(`error getting all places ${error}`);
  }
};

const getAllPlaylists = async () => {
  try {
    console.log("getting all playlists @ getAllPlaylists");
    const { data, error } = await supabase
      .from("playlists")
      .select("*, playlists");
    return data;
  } catch (error) {
    console.log(`error getting all playlists ${error}`);
  }
};

const getAllArtists = async () => {
  try {
    const { data, error } = await supabase.from("artists").select("*");
  } catch (error) {
    console.log(`error getting all artists ${error}`);
  }
};

const getAllSongs = async () => {
  try {
    const { data, error } = await supabase.from("songs").select("*");
    return data;
  } catch (error) {
    console.log(`error getting all songs ${error}`);
  }
};

// Spotify Auth
async function getSpotifyProfile() {
  const { data, error } = await supabase.auth.getSession();
  if (data) {
    console.log(`session data: ${data}`);
  }
  if (error) {
    console.log(`error getting spotify session: ${error}`);
  }
}

async function refreshSpotifyProfile() {
  const { data, error } = await supabase.auth.refreshSession();
  const { session, user } = data;

  if (user) {
    console.log(`spotify user data: ${user}`);
  }

  if (session) {
    console.log(`spotify session data: ${session}`);
  }

  if (error) {
    console.log(`error refreshing spotify session ${error}`);
  }
}

async function signInSpotify() {
  // e.preventDefault();
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: "spotify",
  });
  if (data) {
    return data;
  } else {
    console.log(error);
  }
}

async function emailSignOut() {
  console.log("signing out via email..");
  const { error } = await supabase.auth.signOut();
}

async function signOutSpotify() {
  console.log(`signing out with Spotify`);
  await supabase.auth.signOut();
}

// End of Spotify Auth

export default {
  emailSignOut,
  getAllArtists,
  getAllSongs,
  getAllPlaces,
  getAllPlaylists,
};
