import "./App.css";

import React, { useState } from "react";
import { useUser } from "@supabase/auth-helpers-react";
import useAuthStore from "./stores/AuthStore";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import Authentication from "./components/Authentication";

import PlaylistMap from "./components/PlaylistMap";
import HomeContent from "./components/HomeContent";
// import Footer from "./components/Footer";

import { Orbis } from "@orbisclub/orbis-sdk";
import { Web3Context } from "./contexts/Web3Context";
// import { Routes, Route } from "react-router-dom"

import { Link, Route, Switch } from "wouter";

import Places from "./pages/Places";
import NFTs from "./pages/NFTs";

let orbis = new Orbis();

function App() {
  const user = useUser();
  const [showMAp, setShowMap] = useState();
  const [displayAuth, setDisplayAuth] = useState(false);
  return (
    <div className="App">
      <Web3Context.Provider value={{ orbis }}>
        <ResponsiveAppBar
          setShowMap={setShowMap}
          setDisplayAuth={setDisplayAuth}
        />
        {(user !== null ||
          useAuthStore.getState().walletConnected === true) && (
          <>
            {/* <PlaylistMap /> */}
            <Switch>
              <Route path="/places">{() => <Places />}</Route>
              <Route path="/nfts">{() => <NFTs />}</Route>

              <Route path="/">{() => <PlaylistMap />}</Route>
            </Switch>
            {/* <p style={{color: "white", fontSize: "6rem"}}>AAA</p> */}
          </>
        )}

        {user === null &&
          useAuthStore.getState().walletConnected === false &&
          displayAuth === false && (
            <>
              <HomeContent />
            </>
          )}

        {displayAuth && (
          <>
            <Authentication
              setShowMap={setShowMap}
              setDisplayAuth={setDisplayAuth}
            />
          </>
        )}
        {/* 
        <Routes>
          <Route path="places" element={<Places />} />
        </Routes> */}
      </Web3Context.Provider>
    </div>
  );
}

export default App;
