import { useRef, useEffect } from "react";
// import "./styles.css";
const Autocomplete = ({setName, setVicinity, setLatitude, setLongitude}) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const coordinatesRef = useRef();

  const options = {
    // componentRestrictions: { country: "" },
    // fields: ["geometry"],
    types: ["establishment"],
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();

      // console.log(JSON.stringify(place));
    //   console.log(`latitude: ${place["geometry"]["location"].lat()}`)
    //   console.log(`longitude: ${place["geometry"]["location"].lng()}`)

    setName(place["name"]);
    setVicinity(place["vicinity"]);
    // setVicinity(place["address_components"]);

    setLatitude(place["geometry"]["location"].lat());
    setLongitude(place["geometry"]["location"].lng());

    });

    // coordinatesRef.current = new window.google.maps.places.

  }, []);
  return (
    <div>
      <input type="text" ref={inputRef} placeholder="search for a cafe/bar" />
    </div>
  );
};
export default Autocomplete;
