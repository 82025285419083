import React, { useState, useMemo, useEffect } from "react";
import Map, {
  Marker,
  Popup,
  NavigationControl,
  //FullscreenControl,
} from "react-map-gl";

import usePlaceStore from "../stores/PlaceStore";
import usePlaylistStore from "../stores/PlaylistStore";
import Diversity1Icon from "@mui/icons-material/Diversity1";

import Pins from "./Pins";
import MusicPlayer from "./MusicPlayer";
import CreateCommunityModal from "./CreateCommunityModal";
import EventRequestModal from "./EventRequestModal";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LyricsIcon from "@mui/icons-material/Lyrics";
import BookOnlineIcon from '@mui/icons-material/BookOnline';

import useAuthStore from "../stores/AuthStore";
import { Tooltip } from "@mui/material";
import CreatePlaylistModal from "./CreatePlaylistModal";
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";
import DropSongModal from "./DropSongModal";
import Switch from "@mui/material/Switch";

const PlaylistMap = ({ mode }) => {
  const [popupInfo, setPopupInfo] = useState(null);
  const [playerInfo, setPlayerInfo] = useState(null);
  const [closePlayer, setClosePlayer] = useState(false);
  const user = useUser();
  const supabase = useSupabaseClient();
  const [checked, setChecked] = useState(true);
  const [yourSecondSwitchState, setYourSecondSwitchState] = useState(false);

  //  MODALS
  const [open, setOpen] = useState(false);
  const [openNewPlaylist, setOpenNewPlaylist] = useState(false);
  const [openNewSongDrop, setOpenNewSongDrop] = useState(false);
  const [openEventRequest, setOpenEventRequest] = useState(false);

  const [allPlaces, setAllPlaces] = useState(
    usePlaceStore.getState().places["places"]["data"]
  );

  //HANDLERS
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNewPlaylistOpen = () => setOpenNewPlaylist(true);
  const handleNewPlaylistClose = () => setOpenNewPlaylist(false);

  const handleNewSongDropOpen = () => setOpenNewSongDrop(true);
  const handleNewSongDropClose = () => setOpenNewSongDrop(false);

  const handleEventRequestOpen = () => setOpenEventRequest(true);
  const handleEventRequestClose = () => setOpenEventRequest(false);

  // subscribe to changes on table [places] with postgres changes:
  // https://supabase.com/docs/guides/realtime/postgres-changes

  const [songs, setSongs] = useState([]);

  const getSongsDroppedToPlace = async (placeId) => {
    const response = await supabase
      .from("songs")
      .select("place_id, artist_name, name, song_url, cover_img_url")
      .eq("place_id", placeId)
      .order("created_at", { ascending: false }); // .then((songs) => setSongs(songs));
    // console.log(`getSongsDroppedToPlace >> ${JSON.stringify(response.data)}`)

    if (response.data.length > 0) {
      return response.data;
    } else return [];

    //  const { title, artist, color, image, audioSrc } = tracks[trackIndex];
    // return {title: songs}
  };

  const handleMapModeChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleLibraryPlayer = async (placeId) => {
    const tracks = await getSongsDroppedToPlace(placeId);
  
    if (tracks.length === 0) {
      console.log(`No songs found for placeId: ${placeId}`);
      return; // Şarkı yoksa işlemi sonlandır
    }
  
    setPlayerInfo({
      placeId: popupInfo.id,
      preferredSrc: "library",
      songs: tracks,
    });
  };

  const subscribeToPlaces = () => {
    supabase
      .channel("table-db-changes")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "places",
        },
        (payload) => {
          let updated = [].concat(allPlaces, payload["new"]);
          setAllPlaces(updated);
        } //console.log(payload["new"]) //
      )
      .subscribe();
  };

  // useEffect(() => {
  subscribeToPlaces();
  // }, []);

  const getPlaylistsOfPlace = (placeId) => {
    return usePlaylistStore
      .getState()
      .playlists["playlists"]["data"].filter(
        (playlist) => playlist.place_id === placeId
      );
  };

  const getEmbedSrc = (placeId, platform) => {
    const playlists = getPlaylistsOfPlace(placeId);
  
    // Eğer çalma listesi boşsa, null döndür
    if (playlists.length === 0) {
      return null;
    } else {
      if (platform === "spotify") {
        return playlists[0]["spotify_iframe_src"];
      } else if (platform === "bandcamp") {
        return playlists[0]["bandcamp_iframe_src"];
      } else {
        return null; // Bilinmeyen platform, isteğe bağlı olarak null döndürebilirsiniz.
      }
    }
  };
  // const playFromAudius = async () => {
  //   const response = await fetch(
  //     "https://discoveryprovider.audius.co/v1/tracks/trending?app_name=ExampleApp"
  //   );
  //   const tracks = await response.json();
  //   const topTrack = tracks.data[0];
  //   new Audio(
  //     `https://discoveryprovider.audius.co/v1/tracks/${topTrack.id}/stream?app_name=ExampleApp`
  //   ).play();
  // };
  
  const getPins = async () => {
    const pins = await Promise.all(
      allPlaces.map(async (place, index) => {
        const embedSrc = getEmbedSrc(place.id, "spotify");
        const tracks = await getSongsDroppedToPlace(place.id);
        
        if (!yourSecondSwitchState) {
          return (
            <Marker
              key={`marker-${index}`}
              longitude={place.long}
              latitude={place.lat}
              anchor="bottom"
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setPopupInfo(place);
              }}
            >
              <Pins />
            </Marker>
          );
        } else if (
          embedSrc !== undefined &&
          embedSrc !== null &&
          embedSrc !== "" &&
          tracks !== null &&
          tracks.length > 0
        ) {
          return (
            <Marker
              key={`marker-${index}`}
              longitude={place.long}
              latitude={place.lat}
              anchor="bottom"
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setPopupInfo(place);
              }}
            >
              <Pins />
            </Marker>
          );
        } else {
          return null;
        }
      })
    );
    return pins.filter(pin => pin !== null); // null olan pinleri filtrele
  };
  
  // Usage
  const [pins, setPins] = useState([]);
  useEffect(() => {
    const fetchPins = async () => {
      const result = await getPins();
      setPins(result);
    };
    fetchPins();
  }, [yourSecondSwitchState]); // yourSecondSwitchState değiştiğinde tekrar getPins fonksiyonunu çağır

  return (
    <>
      <Map
        // reuseMaps
        initialViewState={{
          latitude: 38.35578135867809,
          longitude: 31.418293792925226,
          zoom: 1.8,
          bearing: 0,
          pitch: 0,
        }}
        mapStyle={
          checked
            ? "mapbox://styles/mapbox/dark-v9"
            : "mapbox://styles/streamerd/clel7s9sc000i01k78v5mhdk7"
        }
        // mapStyle=
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_APIKEY}
      >
        {/* <GeolocateControl position="top-left" /> */}
        {/* <FullscreenControl position="top-left" /> */}
        <NavigationControl position="bottom-left" />
        <Switch
          color="warning"
          checked={checked}
          onChange={handleMapModeChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        {pins}

        <Switch
        color="secondary"
        checked={yourSecondSwitchState}
        onChange={(e) => {
          setYourSecondSwitchState(e.target.checked);
        }}
        inputProps={{ "aria-label": "controlled" }}
      />


        {popupInfo && (
          <Popup
            // anchor="top-right"
            anchor={
              popupInfo.lat < 0 || popupInfo.long < 0
                ? "bottom-left"
                : "top-right"
            } // TODO: write a method for this.
            longitude={Number(popupInfo.long)}
            latitude={Number(popupInfo.lat)}
            onClose={() => {
              setPopupInfo(null);
            }}
          >
            {popupInfo.hasPage ? (
              <div className="place-name">
                <a href={popupInfo}>{popupInfo.name}</a>
              </div>
            ) : (
              <div className="place-name">{popupInfo.name}</div>
            )}

            <div className="place-location">
              <p>@ {popupInfo.city}</p>

              {/* <button
                className="bandcamp-play-btn"
                onClick={() =>
                  setPlayerInfo({
                    placeId: popupInfo.id,
                    preferredSrc: "bandcamp",
                    bandcampIframeSrc: getEmbedSrc(popupInfo.id, "bandcamp")
                  })
                }
              >
                <span>bandcamp</span>
              </button> */}
            </div>

            <div className="place-action-btns">
              <button
                className="library-play-btn"
                onClick={() => handleLibraryPlayer(popupInfo.id)}
              >
                <LibraryMusicIcon />
              </button>

              {getEmbedSrc(popupInfo.id, "spotify").length > 0 && (
                <button
                  className="spotify-play-btn"
                  onClick={() =>
                    setPlayerInfo({
                      placeId: popupInfo.id,
                      preferredSrc: "spotify",
                      spotifyIframeSrc: getEmbedSrc(popupInfo.id, "spotify"),
                    })
                  }
                >
                  <PlaylistPlayIcon />
                </button>
              )}

              {/* {getEmbedSrc(popupInfo.id, "spotify") === null &&
                user !==
                  null(
                    <button
                      className="spotify-play-btn"
                      onClick={handleNewPlaylistOpen}
                    >
                      <PlaylistAddIcon />
                    </button>
                  )} */}

              {useAuthStore.getState().walletConnected === true &&
                popupInfo.community_id && (
                  <Tooltip
                    title="visit community"
                    placement="right-end"
                    arrow
                    sx={{ fontSize: "19em" }}
                  >
                    <button className="open-community-btn">
                      <a
                        href={
                          "https://app.orbis.club/group/" +
                          popupInfo.community_id
                        }
                        target={"_blank"}
                      >
                        <Diversity1Icon />
                      </a>
                    </button>
                  </Tooltip>
                )}

              {useAuthStore.getState().walletConnected === true &&
                !popupInfo.community_id && (
                  <Tooltip
                    title="create a new community on web3"
                    placement="right-end"
                    arrow
                  >
                    <button
                      className="create-community-btn"
                      onClick={handleOpen}
                    >
                      <GroupAddIcon />
                    </button>
                  </Tooltip>
                )}

              <Tooltip
                title="drop your song to place\'s library"
                placement="right-end"
                arrow
                sx={{ fontSize: "19em" }}
              >
                <button
                  id="drop-song-button"
                  className="drop-song-btn"
                  onClick={handleNewSongDropOpen}
                >
                  {/* <PlaylistAddIcon /> */}

                  <LyricsIcon />
                </button>
              </Tooltip>

              <Tooltip
                title="request an event to this venue"
                placement="right-end"
                arrow
                sx={{ fontSize: "19em" }}
              >
                <button
                  id="request-event-button"
                  className="request-event-btn"
                  onClick={handleEventRequestOpen}
                >
                  {/* <PlaylistAddIcon /> */}

                  <BookOnlineIcon />
                </button>
              </Tooltip>

            </div>
          </Popup>
        )}
      </Map>

      {playerInfo && (
        <div>
          <MusicPlayer
            placeId={playerInfo.placeId}
            preferredSrc={playerInfo.preferredSrc}
            spotifyIframeSrc={playerInfo.spotifyIframeSrc}
            bandcampIframeSrc={playerInfo.bandcampIframeSrc}
            songs={playerInfo.songs}
          />
        </div>
      )}

      {popupInfo && (
        <CreateCommunityModal
          open={open}
          handleClose={handleClose}
          communityName={popupInfo.name}
          placeId={popupInfo.id}
          imgUrl={popupInfo.img}
        />
      )}

      {popupInfo && (
        <CreatePlaylistModal
          open={openNewPlaylist}
          handleClose={handleNewPlaylistClose}
          communityName={popupInfo.name}
          placeId={popupInfo.id}
        />
      )}

      {popupInfo && (
        <DropSongModal
          open={openNewSongDrop}
          handleClose={handleNewSongDropClose}
          placeName={popupInfo.name}
          placeId={popupInfo.id}
        />
      )}

{popupInfo && (
        <EventRequestModal
          open={openEventRequest}
          handleClose={handleEventRequestClose}
          placeName={popupInfo.name}
          placeId={popupInfo.id}
        />
      )}
    </>
  );
};

export default PlaylistMap;
