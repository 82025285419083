import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useState, useContext } from "react";

// import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

import { Orbis } from "@orbisclub/orbis-sdk";
import { TextField } from "@mui/material";
import { Web3Context } from "../contexts/Web3Context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#b888eb",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

// creates a community on web3 @ orbis.club.
// only works for users that logged in with their wallets.
// TODO: we will use supabase buckets for uploading media, until we have a pinata gateway.
export default function CreateCommunityModal({
  open,
  handleClose,
  communityName,
  placeId,
  imgUrl,
}) {
  const [description, setDescription] = useState(""); // email of the user
  // const [imgUrl, setImgUrl] = useState(""); // password of the user
  const [Lmsg, setLMsg] = useState(""); // Login message

  const { orbis } = useContext(Web3Context);

  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  // POPOVER
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };


  const thumbStyle = {
    position: "relative",
    display: "inline-flex",
    borderRadius: 30,
    border: "4px solid white",
    width: 120,
    height: 120,
    marginLeft: "6.8rem",
    marginBottom: "2rem",
    boxSizing: "border-box",
  };

  const createCommunity = async () => {
    console.log(
      `inputs to createCommunity imgUrl: ${imgUrl}\n description: ${description} \n communityName: ${communityName}`
    );

    let res = await orbis.createGroup({
      pfp: imgUrl,
      name: communityName,
      description: description
    });

    if (res.status === 200) {
      console.log(`result of createCommunity: ${JSON.stringify(res)}`);

      console.log(`created community for ${communityName} with id: ${res.doc}`);
    } else {
      console.log("error creating community on orbis.club");
    }
  };

  const openPopover = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // END POPOVER

  // const { ref } = usePlacesWidget({
  //   apiKey: process.env.REACT_APP_GOOGLE_PLACES_APIKEY,
  //   onPlaceSelected: (place) => console.log(place),
  //   options: {
  //     types: ["(cities)"]
  //   },
  // });

  // useEffect(() => {
  //   console.log(`hello`);
  // }, []);

  // async function savePlaylist(placeId) {
  //   const { data, error } = await supabase.from("playlists").insert({
  //     place_id: placeId,
  //     spotify_iframe_src: user.id,
  //     soundcloud_iframe_src: playlistUrl,
  //   });

  //   if (!error) {
  //     console.log(`playlist saved.. ${JSON.stringify(data)}`);
  //   }
  // }

  // async function savePlace() {
  //   const { data, error } = await supabase.from("places").insert({
  //     name: name,
  //     city: vicinity, // TODO: update col from city to vicinity
  //     lat: latitude,
  //     long: longitude,
  //     user_id: user.id,
  //   });

  //   if (!error) {
  //     console.log(`new place data after saved: ${JSON.stringify(data)}`);
  //     setRMsg("thank you. we'll remember this.");
  //     //savePlaylist(placeId)
  //   }
  // }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="create-community-form">

            {imgUrl && 
            <img src={imgUrl} style={thumbStyle}></img>
            }
            
            <TextField
              id="filled-multiline-flexible"
              label="Description"
              multiline
              maxRows={5}
              value={description}
              onChange={handleChange}
              variant="filled"
              sx={{
                backgroundColor: "white",
                width: "80%",
                marginLeft: "2.4rem",
                borderRadius: "4px",
              }}
            />

            {/* <input
              type="text"
              onChange={(e) => setImgUrl(e.target.value)}
              placeholder="Image Url"
            /> */}

            <button
              // style={buttonClicked? {cursor: "not-allowed", pointerEvents: "none"}: null}
              className="retro save-community-btn"
              onClick={createCommunity}
            >
              Create
            </button>
          </div>

          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginTop: "1rem", color: "white" }}
          >
            <i>
              Let's start a web3 community for: <br></br>
              <strong>
                <span style={{ color: "black" }}>{communityName}</span>
              </strong>
            </i>
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
}
