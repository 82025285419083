import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";
import Tabs from "@mui/material/Tabs";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

export default function EventRequestModal({
  open,
  handleClose,
  placeName,
  placeId,
}) {
  const user = useUser();
  const supabase = useSupabaseClient();
  const [Rmsg, setRMsg] = useState(""); // Place saving message
  const [songUrl, setSongUrl] = useState("");
  const [artistName, setArtistName] = useState("");
  const [userId, setUserId] = useState("");
  const [offeredValue, setOfferedValue] = useState("");

  const [offeredSkillTime, setOfferedSkillTime] = useState("");
  const [preferredPlatforms, setPreferredPlatforms] = useState("");
  const [preferredArrangements, setPreferredArangements] = useState("");

  const [uploadStatus, setUploadStatus] = useState();
  const [currency, setCurrency] = useState();

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  async function saveEventRequest() {
    // console.log(`saving event request with values ${artistName} with value: ${offeredValue} & unit: ${currency} \n ${offeredSkillTime}    `)
    const { data, error } = await supabase.from("event_requests").insert({
      user_id: user?.id,
      place_id: placeId,
      user_email: user?.email,
      user_wallet: "",
      for_artist: artistName, // will start with single artist
      preferred_arrangements: preferredArrangements,
      offered_value: offeredValue,
      offered_unit: currency,
      offered_skilltime: offeredSkillTime,
    });

    if (!error) {
      // console.log(data);
      setRMsg(
        `We'll let know ${placeName} and ${artistName} about your request`
      );
    }

    // if(error) {console.log(error)}
  }

  const cleanupWeb3Drop = () => {
    // setCoverImgUrl("");
    // setSongName("");
    // setSongUrl("");
    // setTokenId("");
    // setContractAddress("");
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Request an event @{placeName}.
          </Typography>

          <div className="drop-song-form">
            {user !== null ? (
              <>
                <input
                  type="text"
                  placeholder="Which artist to invite?"
                  onChange={(e) => setArtistName(e.target.value)}
                />

                <div className="offered-value-container">
                  <div className="offered-value">
                    <input
                      type="text"
                      placeholder="offer money?"
                      onChange={(e) =>
                        setOfferedValue(parseInt(e.target.value))
                      }
                    />
                  </div>

                  <div className="offered-currency">
                    <FormControl
                      sx={{
                        minWidth: 140,
                        backgroundColor: "red",
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-autowidth-label"
                        style={{ color: "white", fontSize: "1.1rem" }}
                      >
                        unit
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={currency}
                        onChange={handleCurrencyChange}
                        autoWidth
                        label="unit"
                        // style={{height: "3REM"}}
                      >
                        <MenuItem value={"usd"}>USD</MenuItem>
                        <MenuItem value={"eur"}>Euro</MenuItem>
                        <MenuItem value={"eth"}>ETH</MenuItem>
                        <MenuItem value={"polygon"}>MATIC</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {/* <div className="offered-skilltime"></div> */}

                <input
                  type="text"
                  placeholder="offer skill-time?"
                  onChange={(e) => setOfferedSkillTime(e.target.value)}
                />

                <input
                  type="text"
                  placeholder="any other preference?"
                  onChange={(e) => setPreferredArangements(e.target.value)}
                />

                <button
                  className="retro save-event-btn"
                  onClick={saveEventRequest}
                >
                  Request
                </button>
              </>
            ) : (
              <>
                {uploadStatus !== true && (
                  <div>
                    <input
                      type="text"
                      placeholder="who goes to stage?"
                      onChange={(e) => setArtistName(e.target.value)}
                    />

                    <input
                      type="text"
                      placeholder="offer money/token"
                      onChange={(e) => setOfferedValue(e.target.value)}
                    />

                    <input
                      type="text"
                      placeholder="and/or skills"
                      onChange={(e) => setOfferedSkillTime(e.target.value)}
                    />

                    <input
                      type="text"
                      placeholder=" preferred arrangements?"
                      onChange={(e) => setPreferredArangements(e.target.value)}
                    />
                  </div>
                )}

                <button
                  className="retro fetch-metadata-btn"
                  onClick={saveEventRequest}
                >
                  Request
                </button>
              </>
            )}

            <p>{Rmsg}</p>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
