import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { isWalletConnected } from "../utils/OrbisClient";
import supabase from "../utils/SupabaseClient";

const authStore = (set) => ({
  walletConnected: false,
  emailLoggedIn: false,

  updateEmailConnStatus: (status) => {
    set((state) => ({
      emailLoggedIn: status
    }));
  },

  updateWalletConnStatus: (status) => {
    set((state) => ({
      walletConnected: status
    }));
  },

  checkWalletConnection: () => {
    set((state) => ({
      walletConnected: isWalletConnected().then((result) => {
        state.walletConnected = result;
      }),
    }));
  },

  checkEmailConnection: () => {
    set((state) => ({
      emailLoggedIn: supabase.auth.getSession().then((data) => {
        if (data.session === undefined) {
          state.emailLoggedIn = false;
        } else {
          state.emailLoggedIn = true;
        }
      })
    }));
  },
});

const useAuthStore = create(
    devtools(
        persist(authStore, {
            name: "authStatus"
        })
    )
)


export default useAuthStore;