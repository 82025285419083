import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const playlistStore = (set) => ({
  playlists: [],

  initPlaylists: (initPlaylists) => {
    set((state) => ({
      playlists: initPlaylists,
    }));
  },


  // playlists: getAllPlaylists(),
  addPlaylist: (playlist) => {
    set((state) => ({
      playlists: [playlist, ...state.playlists],
    }));
  },

  removePlaylist: (playlistId) => {
    set((state) => ({
      playlists: state.playlists.filter(
        (playlist) => playlist.id !== playlistId
      ),
    }));
  },

  togglePlaylistStatus: (playlistId) => {
    set((state) => {
      playlists: state.playlists.map((playlist, i) =>
        playlist.id === playlistId
          ? {
              ...playlist,
              publicised: !playlist.publicised,
            }
          : playlist
      );
    });
  },
});

const usePlaylistStore = create(
  devtools(
    persist(playlistStore, {
      name: "playlists",
    })
  )
);

export default usePlaylistStore;
