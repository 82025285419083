import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";
import Autocomplete from "./Autocomplete";

import { v4 as uuidv4 } from "uuid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

export default function AddPlaceModal({ open, handleClose }) {
  const [name, setName] = useState(""); // email of the user
  const [vicinity, setVicinity] = useState(""); // password of the user
  const [latitude, setLatitude] = useState(""); // username of the user
  const [longitude, setLongitude] = useState(""); // username of the user
  const [Rmsg, setRMsg] = useState(""); // Place saving message
  const [playlistUrl, setPlaylistUrl] = useState("");
  const [placeImg, setPlaceImg] = useState();
  const [placeImgUrl, setPlaceImgUrl] = useState("");

  const thumbStyle = {
    position: "relative",
    display: "inline-flex",
    borderRadius: 30,
    border: "4px solid black",
    width: 120,
    height: 120,
    marginLeft: "7.2rem",
    boxSizing: "border-box",
  };

  const supabase = useSupabaseClient();
  const user = useUser();

  // TODO: ask a web3 user if they want to create a community while creating a place as well.
  async function savePlace() {
    // console.log(`saving a new place by ${window.localStorage.getItem("username")}`)
    // console.log(
    //   `saving place with values:\n ${name} \n ${vicinity}\n ${latitude}\n ${longitude}, \n userId:  ${user.id} \n img url: ${placeImgUrl} `
    // );

    // console.log(`imgs >>> ${JSON.stringify(placeImg)}`);

    await fetch("https://n2m-auth-proxy.vercel.app/places/new", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: name,
        city: vicinity,
        lat: latitude,
        long: longitude,
        user_id:
          user !== null ? user.id : window.localStorage.getItem("username"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data === true) {
          setRMsg(`We can see ${name} on the map now.`);
        } else {
          setRMsg("We couldn't save the place");
        }
      });

    // const { data, error } = await supabase.from("places").insert({
    //   name: name,
    //   city: vicinity, // TODO: update col from city to vicinity
    //   lat: latitude,
    //   long: longitude,
    //   user_id: user.id,
    //   img: placeImgUrl,
    // });

    // if (!error) {
    //   console.log(`new place data after saved: ${JSON.stringify(data)}`);
    //   setRMsg("thank you. we'll remember this.");
    //   //savePlaylist(placeId)
    // }
  }

  // const getLastUploadedImg = async () => {
  //   console.log("getting images from storage bucket");

  //   const { data, error } = await supabase.storage
  //     .from("place-imgs")
  //     .list(user?.id + "/", {
  //       limit: 1,
  //       offset: 0,
  //       // sortBy: { column: "name", order: "asc" },
  //       sortBy: { column: "created_at", order: "desc" },
  //     });

  //   if (data !== null) {
  //     setPlaceImg(data[0]);
  //   } else {
  //     console.log(error);
  //   }
  // };

  const getLastUploadedImgUrl = async (imgName) => {
    console.log("getting last uploaded img url");
    let res = supabase.storage
      .from("place-imgs")
      .getPublicUrl(`${user.id}/${imgName}`);

    if (res !== null) {
      const publicURL = res.data.publicUrl;
      setPlaceImgUrl(publicURL);
      console.log(`public URL of last uploaded img: ${publicURL}`);
    }
  };

  // const uploadImage = async (e) => {
  //   console.log("getting images to storage bucket");

  //   let file = e.target.files[0];
  //   console.log(file.name);

  //   let imgName = uuidv4();

  //   const { data, error } = await supabase.storage
  //     .from("place-imgs")
  //     .upload(user.id + "/" + imgName, file);

  //   if (data) {
  //     console.log("uploaded images from storage bucket");

  //     // getLastUploadedImg();
  //     getLastUploadedImgUrl(imgName);
  //   } else {
  //     console.log(error);
  //   }
  // };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Introduce a place resonating your artistic taste.
            <i>You are the gourmet here {";)"}</i>
          </Typography>

          <div className="add-place-form">
            <Autocomplete
              setName={setName}
              setVicinity={setVicinity}
              setLatitude={setLatitude}
              setLongitude={setLongitude}
            />

            {/* {placeImgUrl.length > 0 && (
              <img src={placeImgUrl} style={thumbStyle}></img>
            )}
            <input
              class="block w-full mt-5 mb-5 text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              id="place-img-file-picker"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              onChange={(e) => uploadImage(e)}
            /> */}

            <button className="retro add-place-btn" onClick={savePlace}>
              Save
            </button>

            <p>{Rmsg}</p>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
