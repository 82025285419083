import { useEffect, useState } from "react";
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

function NFTs({walletAddr}) {
  const [nfts, setNfts] = useState([])


  useEffect(() => {
    // const getFavPlaces = async () => {
    //   const places = await supabase
    //     .from("users_fav_places")
    //     .select("place_id")
    //     .eq("email", user.email)
    //     //.limit(100)
      

    //   if (places.data.length === 0) {
    //     console.log("no fav places yet.")
    //     return [];
    //   } else {
    //     console.log(`fav place ids: ${JSON.stringify(places.data)}`)
    //     return places.data;
    //   }


    // };

    // getFavPlaces().then((ids) => setPlaceIds(ids));
  }, []);
  return (
    <div style={{ backgroundColor: "yellow", color: "black" }}>number of NFTs: {nfts.length} </div>
  );
}

export default NFTs;
