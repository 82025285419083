import React, { useEffect, useState } from "react";
import LibraryPlayer from "./LibraryPlayer";
import usePlaylistStore from "../stores/PlaylistStore";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import LeaderLine from "react-leader-line";

function MusicPlayer({
  placeId,
  preferredSrc,
  spotifyIframeSrc,
  bandcampIframeSrc,
  songs,
}) {
  const [currentLine, setCurrentLine] = useState(null);
  const [displayLine, setDisplayLine] = useState(null);

  const getPlaylistsOfPlace = () => {
    return usePlaylistStore
      .getState()
      .playlists["playlists"]["data"].filter(
        (playlist) => playlist.place_id === placeId
      );
  };

  // currently getting only one (first) playlist.
  const getSpotifyEmbedSrc = () => {
    const playlists = getPlaylistsOfPlace();
    return playlists[0]["spotify_iframe_src"];
  };

  // useEffect(() => {
  //   handleLeaderLine();
  //   setDisplayLine(true)
  // }, [placeId]);

  // const handleLeaderLine = () => {
    
  //   if (songs.length == 0) {
  //     // currentLine.remove();
  //     setDisplayLine(true)

  //     console.log(`drawing a new LeaderLine for place: ${placeId}`)
  //     const line = new LeaderLine(
  //       document.getElementById("no-songs-yet-info"),
  //       document.getElementById("drop-song-button"),
  //       { dash: { animation: true }, hide: !displayLine }
  //     );
      
  //     setCurrentLine(line);
  //   } else {
  //   }
  // };

  return (
    <div className="music-player">
      {/* <button className="close-player" onClick={()=> setClosePlayer(true)}>close</button> */}
      <>
        {preferredSrc === "spotify" && (
          <>
            {/* TODO: add previous / next Playlist controls here.  */}
            {/* <div style={{ padding: "1rem", backgroundColor: "white" }}>
              <span>{name}</span> <br></br>
              <span>@{location}</span>
            </div> */}
            <iframe
              title="spotify embed"
              src={spotifyIframeSrc}
              width="100%"
              height="80"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </>
        )}

        {preferredSrc === "bandcamp" && (
          <>
            <iframe
              width="100%"
              height={"396"}
              src={bandcampIframeSrc}
              seamless
            >
              <a href="https://pudelprodukte.bandcamp.com/track/chapel-cottage-richard-fearless-remix">
                Chapel Cottage (Richard Fearless Remix) by Thee Church Ov Acid
                House
              </a>
            </iframe>
          </>
        )}

        {preferredSrc === "library" && songs.length > 0 && (
          <>
            <LibraryPlayer tracks={songs} />
          </>
        )}

        {preferredSrc === "library" && !songs.length > 0 && (
          <>
            <p style={{ color: "white" }} id="no-songs-yet-info">
              {" "}
              no songs @ this place's library yet. <br></br> please use{" "}
              <span
                style={{
                  color: "white",
                  backgroundColor: "red",
                  padding: "4px",
                  fontWeight: "bolder"
                }}
              >
                red
              </span>{" "}
              button to drop the first song.
            </p>
          </>
        )}
      </>
    </div>
  );
}

export default React.memo(MusicPlayer);
