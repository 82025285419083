import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect, useContext } from "react";
import { Web3Context } from "../contexts/Web3Context";
import { Orbis } from "@orbisclub/orbis-sdk";
// import { Link } from "react-router-dom";

// import { connect, disconnect } from "../utils/OrbisClient";
import useAuthStore from "../stores/AuthStore";
import usePlaceStore from "../stores/PlaceStore";
import usePlaylistStore from "../stores/PlaylistStore";
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";
import AddPlaceModal from "./AddPlaceModal";
// import UpdateSettingsModal from "./UpdateSettingsModal";

import { Link, Route, Switch } from "wouter";
import Places from "../pages/Places";

const pages = [];

function ResponsiveAppBar({ username, pfp, setShowMap, setDisplayAuth }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [displayEmailAuth, setDisplayEmailAuth] = useState(false);

  //  MODAL
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenProfile = () => setOpenProfile(true);
  const handleCloseProfile = () => setOpenProfile(false);

  const supabase = useSupabaseClient();
  const user = useUser();
  const { orbis } = useContext(Web3Context);

  const updateEmailLoginStatus = useAuthStore(
    (state) => state.updateEmailConnStatus
  );
  const updateWalletConnectedStatus = useAuthStore(
    (state) => state.updateWalletConnStatus
  );

  const initPlaylists = usePlaylistStore((state) => state.initPlaylists);
  const initPlaces = usePlaceStore((state) => state.initPlaces);

  const handleInitPlaylists = async () => {
    console.log(`initting playlists ...  `);
    const playlists = await getAllPlaylists();
    initPlaylists({
      playlists,
    });
  };

  const handleInitPlaces = async () => {
    console.log(`initting places ...  `);
    const places = await getAllPlaces();
    initPlaces({
      places,
    });
  };

  const makeBackendReq = async () => {
    console.log("fetching from backend..");
    await fetch("http://localhost:3001/")
      .then((response) => response.json())
      .then((data) => console.log(data.msg));
  };

  const makeBackendReq2 = async () => {
    console.log("fetching from backend..");
    await fetch("http://localhost:3001/metadata", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        contract_address: "0xc9ed0c135dd7d8f48374db0600498e26ed4cdde1",
        token_id: "109",
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data.msg));
  };

  const getAllPlaylists = async () => {
    return await supabase.from("playlists").select("*");
  };

  const getAllPlaces = async () => {
    return await supabase.from("places").select("*");
  };

  // const userConnected = async () => {
  //   isWalletConnected().then((status) => {
  //     if (status === true) {
  //       setWalletConn(true);
  //     } else {
  //       setWalletConn(false);
  //     }f
  //   });
  // };

  useEffect(() => {
    const fetchData = async () => {
      await handleInitPlaylists();
      await handleInitPlaces();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (displayEmailAuth === true) {
      setDisplayAuth(true);
    }

    if (displayEmailAuth === false) {
      setDisplayAuth(false);
    }
  }, [displayEmailAuth]);

  const connect = async () => {
    console.log("@ CONNECT");

    if (JSON.parse(window.localStorage.getItem("did")) === null) {
      console.log("SEEMS DID NULL");

      let res = await orbis.connect_v2({
        provider: window.ethereum,
        lit: false,
      });

      if (res.status === 200) {
        // console.log(`connected user, result >>  ${JSON.stringify(res)}`);
        // console.log(`setting did >>  ${res.did}`);
        //  console.log(`did: ${res.did}\n username: ${res.details.}`)
        window.localStorage.setItem("did", res.did);
        if (res.details.profile === null) {
          window.localStorage.setItem("username", res.details.metadata.address);
        } else {
          window.localStorage.setItem("username", res.details.profile.username);
        }
        if (res.details.profile === null) {
          window.localStorage.setItem(
            "pfp",
            `https://robohash.org/${res.did}.png`
          );
        } else {
          window.localStorage.setItem("pfp", res.details.profile.pfp);
        }

        // setShowMap(true);
        return true;
      } else {
        return false;
        // console.log("Error connecting to Ceramic: ", res);
        // alert("Make sure your metamask wallet is unlocked");
      }
    } else {
      console.log("SEEMS ALREADY CONN");
      return true;
    }

    /** Check if connection is successful or not */
  };

  const disconnect = async () => {
    console.log(`disconnecting...`);
    window.localStorage.setItem("did", null);
    window.localStorage.setItem("username", null);
    window.localStorage.setItem("pfp", null);
    window.localStorage.setItem("address", null);
    // setShowMap(false);
    let res = await orbis.logout();

    if (res.status === 200) {
      console.log("disconnected....");
      window.localStorage.clear();
      // setShowMap(false)
      return true;
    } else {
      return false;
    }
  };

  async function emailSignOut() {
    console.log("signing out via email..");
    updateEmailLoginStatus(false);
    setShowMap(false);
    await supabase.auth.signOut();
  }

  async function metamaskDisconnect() {
    console.log("disconnecting via metamask..");

    updateWalletConnectedStatus(false);
    window.localStorage.clear();

    setShowMap(false);
    await disconnect();
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: "black", top: "0", position: "fixed" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src="logo.png" alt="n2m-logo" width={"62px"}></img>

          {/* just for gap btw logo and user profile img */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {/* {page} */}
              </Button>
            ))}
          </Box>
          {/* 
          <button
            style={{ marginRight: "4rem" }}
            onClick={() => makeBackendReq()}
          >
            GET
          </button>
          <button
            style={{ marginRight: "6rem" }}
            onClick={() => makeBackendReq2()}
          >
            POST
          </button> */}

          <Box sx={{ flexGrow: 0 }}>
            {useAuthStore.getState().walletConnected === false &&
              user === null && (
                <>
                  <button
                    className="retro rbtn email-login-join"
                    onClick={() => setDisplayEmailAuth(!displayEmailAuth)}
                  >
                    web2
                  </button>

                  <button
                    className="retro rbtn wallet-connect"
                    onClick={() =>
                      connect().then((status) => {
                        updateWalletConnectedStatus(status);
                        setShowMap(status);
                      })
                    }
                  >
                    web3
                  </button>
                </>
              )}

            {(useAuthStore.getState().walletConnected === true ||
              user !== null) && (
              <>
                {/* <Link style={{ marginRight: "2rem" }} to="/">
                  {" "}
                  map
                </Link>

                <Link style={{ marginRight: "2rem" }} to="/nfts">
                  {" "}
                  my NFTs
                </Link>

                <Link style={{ marginRight: "2rem" }} to="/places">
                  {" "}
                  my places
                </Link> */}

                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <img
                    alt={window.localStorage.getItem("username")}
                    src={window.localStorage.getItem("pfp")}
                    style={{ width: 40, height: 40 }}
                  />
                </IconButton>

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {/* <MenuItem key={"profile"} onClick={handleCloseUserMenu}>
                    <IconButton
                      size="small"
                      sx={{ p: 0 }}
                     
                    >
                      profile
                    </IconButton>
                  </MenuItem> */}
                  {user !== null && (
                    <>
                      {/* <MenuItem
                        key={"update-profile"}
                        onClick={handleCloseUserMenu}
                      >
                        <IconButton
                          size="small"
                          sx={{ p: 0 }}
                          onClick={handleOpenProfile}
                        >
                          {user.email ? user.email : user.wallet_addr}
                        </IconButton>
                      </MenuItem> */}

                      <MenuItem key={"add-place"} onClick={handleCloseUserMenu}>
                        <IconButton
                          size="small"
                          sx={{ p: 0 }}
                          onClick={handleOpen}
                        >
                          new place
                        </IconButton>
                      </MenuItem>
                      <MenuItem key={"logout"} onClick={handleCloseUserMenu}>
                        <IconButton
                          size="small"
                          sx={{ p: 0 }}
                          onClick={() => emailSignOut()}
                        >
                          logout
                        </IconButton>
                      </MenuItem>

                      <AddPlaceModal open={open} handleClose={handleClose} />
                      {/* <UpdateSettingsModal
                        open={openProfile}
                        handleClose={handleCloseProfile}
                        user={user}
                      /> */}
                    </>
                  )}

                  {useAuthStore.getState().walletConnected === true && (
                    <>
                      {/* <MenuItem key={"add-place"} onClick={handleCloseUserMenu}>
                        <IconButton
                          size="small"
                          sx={{ p: 0 }}
                          onClick={handleOpen}
                        >
                          new place
                        </IconButton>
                      </MenuItem> */}

                      <MenuItem key={"add-place"} onClick={handleCloseUserMenu}>
                        <IconButton
                          size="small"
                          sx={{ p: 0 }}
                          onClick={handleOpen}
                        >
                          new place
                        </IconButton>
                      </MenuItem>

                      <MenuItem
                        key={"disconnect"}
                        onClick={handleCloseUserMenu}
                      >
                        <IconButton
                          size="small"
                          sx={{ p: 0 }}
                          onClick={
                            () => metamaskDisconnect()
                            // .then(() => {
                            //   updateWalletConnectedStatus(false);
                            //   setShowMap(false);
                            // })
                          }
                        >
                          disconnect
                        </IconButton>
                      </MenuItem>
                      <AddPlaceModal open={open} handleClose={handleClose} />
                    </>
                  )}
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
