import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Button, Popover } from "@mui/material";
import { useState, useContext } from "react";
import InfoIcon from "@mui/icons-material/Info";


import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#b888eb",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

// creates a community on web3 @ orbis.club.
// only works for users that logged in with their wallets.
// TODO: we will use supabase buckets for uploading media, until we have a pinata gateway.
export default function CreatePlaylistModal({
  open,
  handleClose,
  communityName,
  placeId,
}) {
  const [playlistUrl, setPlaylistUrl] = useState("");
  const supabase = useSupabaseClient();

  const handleChange = (event) => {
    setPlaylistUrl(event.target.value);
  };

  // POPOVER
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  async function savePlaylist() {
    console.log(
      `saving playlist with values:\n  embed url: ${playlistUrl} \n place_id: ${placeId} `
    );

    const { data, error } = await supabase.from("playlists").insert({
      spotify_iframe_src: playlistUrl,
      place_id: placeId,
    });

    if (!error) {
      console.log(`new playlist data after saved: ${JSON.stringify(data)}`);
  
    }
  }

  const openPopover = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // END POPOVER

  // const { ref } = usePlacesWidget({
  //   apiKey: process.env.REACT_APP_GOOGLE_PLACES_APIKEY,
  //   onPlaceSelected: (place) => console.log(place),
  //   options: {
  //     types: ["(cities)"]
  //   },
  // });

  // useEffect(() => {
  //   console.log(`hello`);
  // }, []);

  // async function savePlaylist(placeId) {
  //   const { data, error } = await supabase.from("playlists").insert({
  //     place_id: placeId,
  //     spotify_iframe_src: user.id,
  //     soundcloud_iframe_src: playlistUrl,
  //   });

  //   if (!error) {
  //     console.log(`playlist saved.. ${JSON.stringify(data)}`);
  //   }
  // }

  // async function savePlace() {
  //   const { data, error } = await supabase.from("places").insert({
  //     name: name,
  //     city: vicinity, // TODO: update col from city to vicinity
  //     lat: latitude,
  //     long: longitude,
  //     user_id: user.id,
  //   });

  //   if (!error) {
  //     console.log(`new place data after saved: ${JSON.stringify(data)}`);
  //     setRMsg("thank you. we'll remember this.");
  //     //savePlaylist(placeId)
  //   }
  // }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="create-community-form">
            <input
              type="text"
              onChange={(e) => setPlaylistUrl(e.target.value)}
              placeholder="Playlist embed src url"
            />

            <button
              // style={buttonClicked? {cursor: "not-allowed", pointerEvents: "none"}: null}
              className="retro save-community-btn"
              onClick={savePlaylist}
            >
              Create
            </button>
            <Button
                    style={{ backgroundColor: "black" }}
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    startIcon={<InfoIcon />}
                  />
          </div>

          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginTop: "1rem", color: "white" }}
          >
            <i>
              U are about to create a playlist for:{" "}
              <strong>
                <span style={{ color: "black" }}>{communityName}</span>
              </strong>
            </i>
          </Typography>

          <Popover
            id={id}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical:"top",
              horizontal: "left",
            }}
            // anchorOrigin={{
            //   vertical: "bottom",
            //   horizontal: "left",
            // }}
          >
            <Typography sx={{ p: 1.2 }}>
              <strong>1. </strong>Go to the playlist you want to share and click
              the 3 dots next to it.<br></br>
              <i>Tip: You can also right-click it.</i> <br></br>
              <strong>2. </strong>Select Share.<br></br>
              <strong>3. </strong>Select Embed.<br></br>
              <strong>4. </strong>Copy <strong>src</strong> value of the iframe and paste here.<br></br>
            </Typography>
          </Popover>
        </Box>
      </Fade>
    </Modal>
  );
}
