import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { v4 as uuidv4 } from "uuid";
// if web2 user, get song name, artist name, cover image and an audio file.
// if web3 user, get NFT contract address, token id ( we'll fetch asset urls via querying via alchemy)
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";

import LibraryPlayer from "./LibraryPlayer";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

export default function DropSongModal({
  open,
  handleClose,
  placeName,
  placeId,
}) {
  const user = useUser();
  const supabase = useSupabaseClient();
  const [Rmsg, setRMsg] = useState(""); // Place saving message
  const [coverImgUrl, setCoverImgUrl] = useState("");
  const [songUrl, setSongUrl] = useState("");
  const [songName, setSongName] = useState("");
  const [artistName, setArtistName] = useState("");
  const [songUploadColor, setSongUploadColor] = useState("");
  const [imageUploadColor, setImageUploadColor] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [uploadStatus, setUploadStatus] = useState();

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [network, setNetwork] = useState("");

  const handleNetworkChange = (event) => {
    setNetwork(event.target.value);
  };

  const thumbStyle = {
    position: "relative",
    display: "inline-flex",
    borderRadius: 0,
    border: "4px dashed red",
    width: 200,
    height: 200,
    marginLeft: "10rem",
    marginTop: "2rem",
    boxSizing: "border-box",
  };

  // TODO: ask a web3 user if they want to create a community while creating a place as well.
  async function saveSong() {
    console.log("saving song .... ");
    console.log(
      `saving song with values:\n ${songName} \n for place: ${placeName} :: ${placeId} \n img url: ${coverImgUrl} \n song url: ${songUrl} `
    );

    // console.log(`imgs >>> ${JSON.stringify(placeImg)}`);

    const { data, error } = await supabase.from("songs").insert({
      name: songName,
      uploader_id: user.id,
      song_url: songUrl,
      artist_name: artistName,
      cover_img_url: coverImgUrl,
      place_id: placeId,
    });

    if (!error) {
      console.log(data);
      setRMsg(`${songName} just dropped in ~ `);
      setCoverImgUrl("");
    }
  }

  const getLastUploadedImgUrl = async (imgName) => {
    let res = supabase.storage
      .from("music-covers")
      .getPublicUrl(`${user.id}/${imgName}`);

    if (res !== null) {
      const publicURL = res.data.publicUrl;
      setCoverImgUrl(publicURL);
      setImageUploadColor("primary");
    }
  };

  const getLastUploadedSongUrl = async (songName) => {
    let res = supabase.storage
      .from("songs")
      .getPublicUrl(`${user.id}/${songName}`);

    if (res !== null) {
      const publicURL = res.data.publicUrl;
      setSongUrl(publicURL);
      setSongUploadColor("primary");
    }
  };

  const uploadCoverImage = async (e) => {
    let file = e.target.files[0];
    console.log(file.name);

    let imgName = uuidv4();

    const { data, error } = await supabase.storage
      .from("music-covers")
      .upload(user.id + "/" + imgName, file);

    if (data) {
      getLastUploadedImgUrl(imgName);
    } else {
      console.log(error);
    }
  };

  const uploadAudio = async (e) => {
    let file = e.target.files[0];
    console.log(file.name);

    let songName = uuidv4();

    const { data, error } = await supabase.storage
      .from("songs")
      .upload(user.id + "/" + songName, file);

    if (data) {
      getLastUploadedSongUrl(songName);
    } else {
      console.log(error);
    }
  };

  const fetchNFTMetadata = async () => {
    // console.log(`fetching metadata for: ${contractAddress}/${tokenId}`);
    await fetch("https://n2m-auth-proxy.vercel.app/drops/fetch-metadata", {
      // await fetch("http://localhost:3001/drops/fetch-metadata", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        contract_address: contractAddress,
        token_id: tokenId,
        network: network,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(
        //   `NFT metadata returned from backend: ${JSON.stringify(data)}`
        // );
        setSongName(data.name);
        if (data.animation_url) {
          setSongUrl(data.animation_url);
          setCoverImgUrl(data.image);
        } else {
          setSongUrl(data.image);
        }
      });
  };

  const cleanupWeb2Drop = () => {
    setCoverImgUrl("");
    setSongName("");
    setSongUrl("");
  };

  const cleanupWeb3Drop = () => {
    setCoverImgUrl("");
    setSongName("");
    setSongUrl("");
    setTokenId("");
    setContractAddress("");
    setNetwork("");
  };

  const dropNFT = async () => {
    // console.log(`fetching metadata for: ${contractAddress}/${tokenId}`);
    await fetch("https://n2m-auth-proxy.vercel.app/drops/save-song", {
      // await fetch("http://localhost:3001/drops/save-song", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        contractAddress: contractAddress,
        tokenId: tokenId,
        network: network,
        songName: songName,
        artistName: artistName,
        songUrl: songUrl,
        coverImgUrl: coverImgUrl,
        placeId: placeId,
        uploader:
          user !== null ? user.id : window.localStorage.getItem("username"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(`uploadStatus: ${data}`);

        if (data === true) {
          setUploadStatus(true);
          cleanupWeb3Drop();
        } else {
          setUploadStatus(false);
        }
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {value === 0 && uploadStatus !== true && (
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Drop one of your songs to <br></br>
              {placeName}'s library.
            </Typography>
          )}

          {value === 1 && uploadStatus !== true && (
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Drop one of your music NFTs to <br></br>
              {placeName}'s library.
            </Typography>
          )}

          {uploadStatus !== true && user !== null && (
            <Tabs
              value={value}
              onChange={handleTabChange}
              centered
              style={{ marginTop: "2rem" }}
            >
              <Tab
                label="web2"
                style={{
                  fontWeight: "800",
                  color: value === 0 ? "white" : "black",
                  fontSize: "1.2rem",
                  backgroundColor: value === 0 ? "black" : "white",
                }}
              />
              <Tab
                label="web3"
                style={{
                  fontWeight: "800",
                  color: value === 1 ? "white" : "black",
                  fontSize: "1.2rem",
                  backgroundColor: value === 1 ? "black" : "white",
                }}
              />
            </Tabs>
          )}

          <div className="drop-song-form">
            {value === 0 && user !==null ? (
              <>
                <input
                  type="text"
                  placeholder="song name"
                  onChange={(e) => setSongName(e.target.value)}
                />

                <input
                  type="text"
                  placeholder="artist/band name"
                  onChange={(e) => setArtistName(e.target.value)}
                />
                <p>add sound and image files</p>
                <div className="drop-song-form-uploads">
                  <label htmlFor="song-upload">
                    {" "}
                    <UploadFileIcon
                      fontSize="large"
                      color={songUploadColor}
                    />{" "}
                  </label>
                  <input
                    class="block w-full mt-5 mb-5 text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    id="song-upload"
                    type="file"
                    accept="audio/mp3, audio/wav, audio/m4a, audio/mp4"
                    onChange={(e) => uploadAudio(e)}
                  />

                  <label htmlFor="song-cover-upload">
                    {" "}
                    <AddPhotoAlternateIcon
                      fontSize="large"
                      color={imageUploadColor}
                    />{" "}
                  </label>

                  <input
                    class="block w-full mt-5 mb-5 text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    id="song-cover-upload"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e) => uploadCoverImage(e)}
                  />
                </div>
                {coverImgUrl.length > 0 && (
                  <img src={coverImgUrl} style={thumbStyle}></img>
                )}

                <button className="retro save-song-btn" onClick={saveSong}>
                  Save
                </button>
              </>
            ) : (
              <>
                {uploadStatus !== true && (
                  <div>
                    <FormControl
                      sx={{
                        m: 1,
                        minWidth: 140,
                        backgroundColor: "red",
                        marginLeft: "3.2rem",
                        marginBottom: "1.8rem",
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-autowidth-label"
                        style={{ color: "white", fontSize: "1.2rem" }}
                      >
                        network
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={network}
                        onChange={handleNetworkChange}
                        autoWidth
                        label="network"
                      >
                        <MenuItem value={"eth"}>Ethereum</MenuItem>
                        <MenuItem value={"polygon"}>Polygon</MenuItem>
                      </Select>
                    </FormControl>
                    <input
                      type="text"
                      placeholder="token address"
                      onChange={(e) => setContractAddress(e.target.value)}
                    />

                    <input
                      type="text"
                      placeholder="tokenID"
                      onChange={(e) => setTokenId(e.target.value)}
                    />
                  </div>
                )}

                {uploadStatus === true && (
                  <p style={{ marginLeft: "3rem" }}>
                    Your song just dropped in. <br></br> Close this window and
                    click <LibraryMusicIcon /> to listen.{" "}
                  </p>
                )}

                {songUrl.length > 0 && (
                  <LibraryPlayer
                    tracks={[
                      {
                        song_name: songName,
                        artist_name: artistName,
                        cover_img_url: coverImgUrl,
                        song_url: songUrl,
                      },
                    ]}
                  />
                )}

                {songUrl.length > 0 && (
                  <button
                    className="retro drop-nft-btn"
                    onClick={dropNFT}
                    disabled={uploadStatus}
                  >
                    Drop NFT
                  </button>
                )}

                {/* {coverImgUrl.length > 0 && (
                  <img src={coverImgUrl} style={thumbStyle}></img>
                )}

                {songUrl.length > 0 && (
                  <div>
                    <audio
                      src={songUrl}
                      controls
                    />
                   
                  </div>
                )} */}

                {songUrl.length === 0 && uploadStatus !== true && (
                  <button
                    className="retro fetch-metadata-btn"
                    onClick={fetchNFTMetadata}
                  >
                    Fetch NFT
                  </button>
                )}
              </>
            )}

            <p>{Rmsg}</p>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
