import { memo } from 'react'
// import QueueMusicIcon from "@mui/icons-material/QueueMusic";
// import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
function getRandomColor() {
  return "#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0");
}

function Pins() {
  // return <SlowMotionVideoIcon htmlColor={"#ffb56d"} sx={{ fontSize: 24, fontWeight: 500 }} />;
  return <SlowMotionVideoIcon htmlColor={getRandomColor()} sx={{ fontSize: 24, fontWeight: 500 }} />;

}

export default memo(Pins);
