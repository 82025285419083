
import { useContext, useState } from "react";
import { Web3Context } from "../contexts/Web3Context";

// import { Orbis } from "@orbisclub/orbis-sdk";
 let client;
export const OrbisClient = () => {
  const {  orbis } = useContext(Web3Context);
  const [client, setClient] = useState();
  setClient(orbis)
}



export const isWalletConnected = async () => {
  let res = await client.isConnected();
  console.log(`orbis connection :: ${res.status}`);

  if (res.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const getUserProfile = async (did) => {
  console.log(`getting details for user with did: ${did}`);
  const connected = isWalletConnected();
  if (connected) {
    let { data, error } = await client.getProfile(did);
    if (data) {
      // window.localStorage.setItem("username", data.details.profile.username);
      // window.localStorage.setItem("pfp", data.details.profile.pfp);
      // window.localStorage.setItem("address", data.address);
      return data;
    }
  } else {
    // alert("user seems disconnected..");
  }
};

export async function updateUserProfile(pfp, username, data) {
  console.log(`updating user profile..`);
  await client.updateProfile({
    pfp: pfp,
    username: username,
    data: data,
    // data: { favoriteGenres: ["pop", "rock", "reggae"] },
  });
}

export const connect = async () => {
  console.log("@ CONNECT");

  if (JSON.parse(window.localStorage.getItem("did")) === null) {
    console.log("SEEMS DID NULL");

    let res = await client.connect_v2({
      provider: window.ethereum,
      lit: false,
    });

    if (res.status === 200) {
      // console.log(`connected user, result >>  ${JSON.stringify(res)}`);
      // console.log(`setting did >>  ${res.did}`);
      //  console.log(`did: ${res.did}\n username: ${res.details.}`)
      window.localStorage.setItem("did", res.did);
      if (res.details.profile === null) {
        window.localStorage.setItem("username", res.details.metadata.address);
      } else {
        window.localStorage.setItem("username", res.details.profile.username);
      }
      if (res.details.profile === null) {
        window.localStorage.setItem(
          "pfp",
          `https://robohash.org/${res.did}.png`
        );
      } else {
        window.localStorage.setItem("pfp", res.details.profile.pfp);
      }

      // setShowMap(true);
      return true;
    } else {
      return false;
      // console.log("Error connecting to Ceramic: ", res);
      // alert("Make sure your metamask wallet is unlocked");
    }
  } else {
    console.log("SEEMS ALREADY CONN");
    return true;
  }

  /** Check if connection is successful or not */
};

export const disconnect = async () => {
  console.log(`disconnecting...`);
  window.localStorage.setItem("did", null);
  window.localStorage.setItem("username", null);
  window.localStorage.setItem("pfp", null);
  window.localStorage.setItem("address", null);
  // setShowMap(false);
  let res = await client.logout();

  if (res.status === 200) {
    console.log("disconnected....")
    // setShowMap(false)
    return true;
  } else {
    return false;
  }
};

export const getCommunity = async (communityId) => {
  let { data, error } = await client.getGroup(communityId);
}

export const createCommunity = async (pfp, name) => {
  let res = await client.createGroup({
    pfp: pfp,
    name: name,
  });
};

export const updateCommunity = async (communityId, pfp, name, description) => {
  let res = await client.updateGroup(communityId, {
    pfp: pfp,
    name: name,
    description: description,
  });
};

export const updateMembership = async (communityId, active) => {
  // joining community if active:true, leaving community if active:false
  let res = await client.setGroupMember(communityId, active);
};
export const getCommunityMembers = async (communityId) => {
  let { data, error } = await client.getGroupMembers(communityId);
};

export const getUserCommunities = async (did) => {
  let { data, error } = await client.getProfileGroups(did);
};

export const isCommunityMember = async (communityId, did) => {
  let { data, error } = await client.getIsGroupMember(communityId, did);
};


// export {
//   connect,
//   createCommunity,
//   disconnect,
//   isWalletConnected,
//   isCommunityMember,
//   getCommunity,
//   getUserProfile,
//   getCommunityMembers,
//   getUserCommunities,
//   updateCommunity,
//   updateMembership,
//   updateUserProfile
// };
