import React, { useState } from "react";

function HomeContent() {
  const [displayImage, setDisplayImage] = useState(true);

  return (
    <>
      {/* <p>
        hey there
      </p> */}
      <div className="home-page-content">
        <button
          className="retro rbtn see-how-it-works-btn"
          onClick={() => setDisplayImage(!displayImage)}
        >
          see how it works
        </button>
        {!displayImage && (
          <div className="demo-iframe">
            <iframe
              width="100%"
              // height="400"
              src="https://www.youtube-nocookie.com/embed/c9ERLdG69bE"
              title="demo video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        )}

        {displayImage && (
          <div className="intro-image">
            <img src="taste_of_art.jpg" alt="taste of art"></img>
          </div>
        )}
      </div>
    </>
  );
}

export default HomeContent;
