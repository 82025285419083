import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { createClient } from "@supabase/supabase-js";
import { BrowserRouter } from "react-router-dom";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_CLIENT_URL,
  process.env.REACT_APP_SUPABASE_ANONKEY,
  {
    realtime: {
      headers: {
        apikey: process.env.REACT_APP_SUPABASE_ANONKEY,
      },
      params: {
        apikey: process.env.REACT_APP_SUPABASE_ANONKEY,
        eventsPerSecond: 10,
      },
    },
  }
);

ReactDOM.render(
  <BrowserRouter>
    <SessionContextProvider supabaseClient={supabase}>
      <App />
     
    </SessionContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals(sendToVercelAnalytics);
