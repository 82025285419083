import { useEffect, useState } from "react";
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

function Places({user_profile_id}) {
  const supabase = useSupabaseClient();
  const user = useUser();
  const [placeIds, setPlaceIds] = useState([])

  useEffect(() => {
    const getFavPlaces = async () => {
      const places = await supabase
        .from("users_fav_places")
        .select("place_id")
        .eq("email", user.email)
        //.limit(100)
      

      if (places.data.length === 0) {
        console.log("no fav places yet.")
        return [];
      } else {
        console.log(`fav place ids: ${JSON.stringify(places.data)}`)
        return places.data;
      }


    };

    getFavPlaces().then((ids) => setPlaceIds(ids));
  }, []);
  return (
    <div style={{ backgroundColor: "yellow", color: "black" }}>number of fav places: {placeIds.length} </div>
  );
}

export default Places;
